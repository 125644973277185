// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

var App = {
	initOnReady: function()
	{
		App.initBackstretch();
		App.initLightbox();
		App.initRichContent();
		App.initFilter();
		App.initSliders();
		App.initSelect();
		App.initSocialPopup();

		App.initCompareProducts();
		App.resizeElements();
	},

	initOnResize: function()
	{
		App.resizeElements();
	},

	initBackstretch: function()
	{
		if (typeof backstretchslides != 'undefined')
		{
			$('section.banner').backstretch(
				backstretchslides,
				{
					duration: 4000,
					transition: 'push_left',
					transitionDuration: 1000,
					animateFirst: false
				}
			);
		}
	},

	initCompareProducts: function()
	{
		if ($('input[name="compare[]"]').length && (typeof urlProducts != 'undefined'))
		{
			App.toggleCompareProducts();

			$('input[name="compare[]"]').on('click', function()
			{
				App.toggleCompareProducts();
			});

			// set event
			$('button.compare-products')
				.on('click', function()
				{
					var ids = [];

					$('input[name="compare[]"]:checked').each(function()
					{
						ids.push($(this).val());
					});

					if (ids.length)
					{
						window.location = urlProducts + '?compare=' + ids.join(',');
					}
				})
				;
		}
	},

	nouisliders: {},
	initFilter: function()
	{
		if ($('.nouislider-range').length)
		{
			var min, max,
				x, y,
				elX, elY,
				valueX, valueY,
				el
				;

			$('.nouislider-range').each(function()
			{
				min = parseInt($(this).attr('data-min'));
				max = parseInt($(this).attr('data-max'));
				x = $(this).attr('data-x');
				y = $(this).attr('data-y');
				el = $(this).get(0);
				elX = $('input[name="' + x + '"]');
				elY = $('input[name="' + y + '"]');

				// set values
				valueX = parseInt(elX.val());
				valueY = parseInt(elY.val());
				if (isNaN(valueX)) valueX = min;
				if (isNaN(valueY)) valueY = max;
				if ((valueX < min) || (valueX > max)) valueX = min;
				if ((valueY < min) || (valueY > max)) valueY = max;

				//console.log(x + ': ' + valueX +  ' -- ' + y + ': ' + valueY);

				App.nouisliders[x] = noUiSlider.create(el,
				{
					start: [valueX, valueY],
					step: 1,
					tooltips: true,
					format:
					{
						to: function ( value )
						{
							return parseInt(value);
						},
						from: function ( value )
						{
							return parseInt(value);
						}
					},
					range:
					{
						min: min,
						max: max
					},
					x: elX,
					y: elY
				});

				App.nouisliders[x].on('set', function(values)
				{
					this.options.x.val(values[0]);
					this.options.y.val(values[1]);
				});
			});
		}
	},

	initLightbox: function()
	{
		$('.lightbox').fancybox(
		{
			overlayOpacity: 0.8,
			overlayColor: 'black',
			padding: 0,
			titleShow: true
		});

		$('.lightbox-media').fancybox(
		{
			overlayOpacity: 0.8,
			overlayColor: 'black',
			padding: 0,
			titleShow: true,
			helpers : {
				media : {}
			}
		});
	},

	initMaps: function()
	{
		var
			styles = [
				{
					featureType: 'all',
					elementType: 'geometry',
					stylers: [
						{
							visibility: 'on'
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels',
					stylers: [
						{
							visibility: 'simplified'
						},
						{
							saturation: '7'
						},
						{
							gamma: '1.37'
						},
						{
							weight: '0.01'
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							visibility: 'on'
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'all',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'administrative',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#444444'
						}
					]
				},
				{
					featureType: 'landscape',
					elementType: 'all',
					stylers: [
						{
							color: '#f2f2f2'
						}
					]
				},
				{
					featureType: 'poi',
					elementType: 'all',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road',
					elementType: 'all',
					stylers: [
						{
							saturation: -100
						},
						{
							lightness: 45
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'all',
					stylers: [
						{
							visibility: 'simplified'
						}
					]
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'transit',
					elementType: 'all',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'water',
					elementType: 'all',
					stylers: [
						{
							color: '#45BDEE'
						},
						{
							visibility: 'on'
						}
					]
				}
			];

		var styledMap = new google.maps.StyledMapType(styles, {name: ''});
		var elements = ['map', 'map-large'];

		for (var i in elements)
		{
			if ($('#' + elements[i]).length)
			{
				var gmap = new google.maps.Map($('#' + elements[i]).get(0),
				{
					zoom: 12,
					center: new google.maps.LatLng(GoogleMaps.lat, GoogleMaps.lng),
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					mapTypeControl: true,
					streetViewControl: true,
					scrollwheel: false
				});

				gmap.mapTypes.set('map_style', styledMap);
				gmap.setMapTypeId('map_style');

				var gmap_marker = new google.maps.Marker(
				{
					position: new google.maps.LatLng(GoogleMaps.lat, GoogleMaps.lng),
					map: gmap,
					draggable: false,
					animation: google.maps.Animation.DROP,
					icon:
					{
						url: "/img/map-pin.png"
					}
				});
			}
		}
	},

	initRichContent: function()
	{
		// Youtube/Vimeo thumbs
		$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
		$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

		function startVideo(e)
		{
			var block = $(e.currentTarget);
			var iframe = $('iframe', block.parent());
			var src = iframe.attr('src');

			if (src.indexOf('autoplay=0') > -1)
			{
				iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}

			$('.rc-video__overlay', block.parent()).fadeOut(150);
			$('.rc-video__thumb', block.parent()).fadeOut(150);
		}

		// Quote slider
		$('.rc-quote-slider__slick').slick(
		{
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		});

		// Image slider
		$('.rc-image-slider__slick').slick(
		{
			adaptiveHeight: true,
			dots: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		});
	},

	initSliders: function()
	{
		if ($('.slider-banner').length)
		{
			$('.slider-banner').slick(
			{
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				autoplay: true,
  				autoplaySpeed: 6000,
				prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
				nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
			});
		}

		if ($('.slider-contacts').length)
		{
			$('.slider-contacts').slick(
			{
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false
			});
		}

		if ($('.slider-detail').length)
		{
			$('.slider-detail').slick(
			{
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				asNavFor: '.slider-nav',
				prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
				nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
			});
		}

		if ($('.slider-nav').length)
		{
			$('.slider-nav').slick(
			{
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.slider-detail',
				centerMode: true,
				focusOnSelect: true,
				prevArrow: '<button class="slick-prev"><i class="icon-angle-left"></i></button>',
				nextArrow: '<button class="slick-next"><i class="icon-angle-right"></i></button>'
			});
		}
	},

	initSelect: function(){
		$('.aside select').select2({
			minimumResultsForSearch: -1,
			width: '100%',
		});
	},

	initSocialPopup: function()
	{
		if ($('.social-popup'))
		{
			$('.social-popup a')
				.on('click', function(e)
				{
					e.preventDefault();

					var width = 600,
						height = 450,
						top = 100,
						popupName = 'popup',
						left = (screen.width-width) / 2,
						params = [
							'width=' + width,
							'height=' + height,
							'location=no',
							'menubar=no',
							'scrollbars=yes',
							'status=no',
							'toolbar=no',
							'left=' + left,
							'top=' + top
						]
						;

			          window[popupName] = window.open($(this).attr('href'), popupName, params.join(','));

			          if (window.focus)
			          {
			          	window[popupName].focus();
			          }
				})
				;
		}
	},

	resizeElements: function()
	{
		var
			el = [
				'table.table-compare article .product__text',
				'.eq-height-3',
				'.eq-height-2',
				'.eq-height'
			],
			index,
			maxHeight,
			height
			;

		for (index in el)
		{
			if ($(el[index]).length)
			{
				maxHeight = 0;

				$(el[index]).each(function()
				{
					height = $(this).removeAttr('style').outerHeight();

					if (height > maxHeight)
					{
						maxHeight = height;
					}
				});

				$(el[index])
					.css(
					{
						height: maxHeight + 'px'
					})
					;
			}
		}

	},

	toggleCompareProducts: function()
	{
		var i = $('input[name="compare[]"]:checked').length;

		// reset vars
		$('input[name="compare[]"]').removeAttr('disabled');
		$('.compare-products-wrapper').addClass('d-none');
		$('#warningMaximumItemsToCompare').addClass('d-none');
		if (typeof maxItemsAllowedToCompare == 'undefined')
		{
			maxItemsAllowedToCompare = 3;
		}

		if (i > 1)
		{
			$('.compare-products-wrapper').removeClass('d-none');

			if (i >= maxItemsAllowedToCompare)
			{
				$('#warningMaximumItemsToCompare').removeClass('d-none');
				$('input[name="compare[]"]:not(:checked)').attr('disabled', 'disabled');
			}
		}
	},
}

$(document).ready(function ()
{
	App.initOnReady();
});

$(window).on('load', function ()
{
	/*
	 *	SCROLL REVEAL
	 */

	window.sr = ScrollReveal({ scale: 1, duration: 350 });

	sr.reveal('.sr-top', {origin: 'top'}, 50);
	sr.reveal('.sr-left', {origin: 'left'}, 50);
	sr.reveal('.sr-right', {origin: 'right'}, 50);
	sr.reveal('.sr-bottom', {origin: 'bottom'}, 50);
});

$(window).on('resize', function()
{
	App.initOnResize();
});

// google maps
window.initMaps = function()
{
	window.initMaps = null;
	App.initMaps();
}
